<template>
    <v-container class="listAttivita">
        <v-data-iterator :items="attivita"
                         :items-per-page.sync="itemsPerPage"
                         :page="page"
                         :search="search"
                         :sort-by="sortBy.toLowerCase()"
                         :sort-desc="sortDesc"
                         hide-default-footer
                         no-data-text="Ancora Nessuna Attività"
                         no-results-text="Nessuna Attività"
        >
            <template v-slot:header>
                <v-toolbar color="secondary" class="mb-5">
                    <v-text-field v-model="search"
                                  clearable
                                  flat
                                  solo-inverted
                                  hide-details
                                  background-color="white"
                                  label="Cerca"
                                  class="blackTextWriting"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon small>fas fa-search</v-icon>
                        </template>
                    </v-text-field>
                    <v-btn v-if="isMaster && !$vuetify.breakpoint.mdAndUp"
                           class="ml-2"
                           color="primary"
                           fab
                           depressed
                           small
                           to="/newAttivita"
                    >
                        <v-icon small>fas fa-plus</v-icon>
                    </v-btn>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-select v-model="sortBy"
                                  flat
                                  solo-inverted
                                  hide-details
                                  background-color="white"
                                  :items="keys"
                                  label="Ordina Per"
                                  class="blackTextWriting"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon small>fas fa-sort</v-icon>
                            </template>
                        </v-select>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-btn-toggle v-model="sortDesc" mandatory>
                            <v-btn large height="40px" depressed color="primary" :value="false">
                                <v-icon small color="white">fas fa-chevron-up</v-icon>
                            </v-btn>
                            <v-btn large height="40px" depressed color="primary" :value="true">
                                <v-icon small color="white">fas fa-chevron-down</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        <v-btn v-if="isMaster"
                               class="ml-2"
                               color="primary"
                               fab
                               depressed
                               small
                               to="/newAttivita"
                        >
                            <v-icon small>fas fa-plus</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row>
                    <v-col v-for="item in props.items"
                           :key="item._id"
                           cols="12"
                           sm="6"
                           md="4"
                           lg="3"
                    >
                        <v-card :to="'/schedaAttivita?_id='+item._id">
                            <v-card-title class="subheading font-weight-bold lightGrey" style="display: block">
                                {{ item.tipo_attivita }}
                                <v-btn v-if="item.stato && item.stato === 'Conclusa'" :to="'/stampaAttivita?_id='+item._id" text style="float: right; min-width: 0">
                                    <v-icon small>fas fa-print</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-list dense>
                                <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                                    <v-list-item-content :class="sortBy === key ? 'secondary--text bold' : 'bold'">
                                        <small>{{ replaceUnderscore(capitalize(key)) }}</small>
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end" :class="{ 'secondary--text bold--text': sortBy === key }">
                                        <template v-if="key === 'DDT'">
                                            <span v-if="item['ddt'] && item['ddt']['_id']">S&igrave;</span>
                                            <span v-else>No</span>
                                        </template>
                                        <template v-else-if="key === 'stato'">
                                            <v-chip style="max-width:fit-content!important" class="white--text" :color="switchColorStato(item['stato'])">
                                                <b>{{ item['stato'] }}</b>
                                            </v-chip>
                                        </template>
                                        <template v-else-if="key === 'data_scadenza' || key === 'data_inizio'">{{ formatDate(item[key.toLowerCase()]) }}</template>
                                        <template v-else><div class="trimTextTooLong">{{ item[key.toLowerCase()] }}</div></template>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2" align="center" justify="center">
                    <v-spacer></v-spacer>
                    <span class="mr-4 grey--text">Pagina {{ page }} di {{ numberOfPages }}</span>
                    <v-btn fab small color="primary" class="mr-1 white--text" @click="formerPage">
                        <v-icon small class="white--text">fas fa-chevron-left</v-icon>
                    </v-btn>
                    <v-btn fab small color="primary" class="ml-1" @click="nextPage">
                        <v-icon small>fas fa-chevron-right</v-icon>
                    </v-btn>
                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</template>

<script>
import { momentDate } from '@/js/formatDate';
import apiAttivita from '@/js/pages/attivita';
const FiltriLista = () => import('@/components/FiltriLista');

export default {
	name: 'listAttivita',
	components: {
		FiltriLista
	},
	data() {
		return {
			attivita: [],
			token: this.$cookies.get('token'),
			search: '',
			filter: {},
			sortDesc: true,
			page: 1,
			itemsPerPage: 10,
			sortBy: 'numero',
			keys: [
				'numero',
				'note',
				'DDT',
				'data_scadenza',
				'stato',
			],
			isMaster: false,
		}
	},
	computed: {
		numberOfPages () {
			return Math.ceil(this.attivita.length / this.itemsPerPage)
		},
		filteredKeys () {
			return this.keys.filter(key => key !== 'Name')
		},
	},
	async created() {
		if(this.$store.state.ruolo === 'Cliente'){
			this.isMaster = false;
			this.attivita = await apiAttivita.getAllMine(this.token);
		} else if(this.$store.state.ruolo === 'Partner') {
			this.keys.splice(3, 0, "nome_cliente");
			this.keys.splice(4, 0, "indirizzo_stab");
			this.isMaster = true;
			this.attivita = await apiAttivita.getAllPartner(this.token);
		} else {
			this.keys.splice(3, 0, "nome_cliente");
			this.keys.splice(4, 0, "indirizzo_stab");
			this.isMaster = true;
			this.attivita = await apiAttivita.getAll(this.token);
		}
	},
	methods: {
		capitalize(string) {
			if(string) return string.charAt(0).toUpperCase() + string.slice(1);
			else return '';
		},
		replaceUnderscore(string) {
			if(string) return string.replace(/_/g, " ");
			else return '';
		},
		viewAttivita(id) {
			this.$router.push('/schedaAttivita?_id='+id)
		},
		nextPage () {
			if (this.page + 1 <= this.numberOfPages) this.page += 1
		},
		formerPage () {
			if (this.page - 1 >= 1) this.page -= 1
		},
		updateItemsPerPage (number) {
			this.itemsPerPage = number
		},
		formatDate(date) {
			return momentDate(date);
		},
		switchColorStato(stato){
			switch (stato) {
				case 'Nuova':
					return '#2EB2FF';
				case 'In Elaborazione':
					return '#65BE0A';
				case 'Sospesa':
					return '#FF333A';
				case 'Conclusa':
					return '#FFA500';
				default:
					return '#2EB2FF';
			}
		}
	}
}
</script>
