import moment from 'moment';

// moment.locale('it');

export const momentDate = (date) => {
    if (date) return moment(String(date)).format('DD/MM/YYYY');
    else return undefined;
};

export const checkMoreThan2Years = (date) => {
    if (date) {
        date = moment.unix(date);
        let oggi = moment();
        let calcolo = moment.duration(oggi.diff(date)).asMonths();
        if(
            date &&
            calcolo >= 24
        ) return 1;
        else if (
            date &&
            calcolo < 24 &&
            calcolo >= 19
        ) return 2;
        else return false;
    } else return false;
};

export const checkMoreThan4Years = (date) => {
    if (date) {
        date = moment.unix(date);
        let oggi = moment();
        let calcolo = moment.duration(oggi.diff(date)).asMonths();
        if(
            date &&
            calcolo >= 48
        ) return 1;
        else if (
            date &&
            calcolo < 48 &&
            calcolo >= 35
        ) return 2;
        else return false;
    } else return false;
};

export const checkMoreThan6Months = (date) => {
    if (date) {
        date = moment.unix(date);
        let oggi = moment();
        let calcolo = moment.duration(oggi.diff(date)).asMonths();
        if(
            date &&
            calcolo >= 6
        ) return 1;
        else if (
            date &&
            calcolo < 6 &&
            calcolo >= 5
        ) return 2;
        else return false;
    } else return false;
};

export const checkMoreThan12Months = (date) => {
    if (date) {
        date = moment.unix(date);
        let oggi = moment();
        let calcolo = moment.duration(oggi.diff(date)).asMonths();
        if(
            date &&
            calcolo >= 12
        ) return 1;
        else if (
            date &&
            calcolo < 12 &&
            calcolo >= 11
        ) return 2;
        else return false;
    } else return false;
};

export const nearDate = (date) => {
    if (date) {
        date = moment(date);
        let oggi = moment();
        let calcolo = moment.duration(date.diff(oggi)).asMonths();
        if(
            date &&
            calcolo <= 0
        ) return 1;
        else if (
            date &&
            calcolo > 0 &&
            calcolo <= 1
        ) return 2;
        else return false;
    } else return false;
};

export const momentYMD = (date) => {
    if (date) return moment(String(date)).format('YYYY-MM-DD');
    else return undefined;
};

export const momentHM = (date) => {
    if (date) return moment(String(date)).format('HH:mm');
    else return undefined;
};

export const momentLL = (date) => {
    if (date) return moment(String(date)).format('LL');
    else return undefined;
};

export const unixToItalian = (unixString) => {
    if (unixString) return moment.unix(unixString).format("DD/MM/YYYY");
    else return '';
};
