import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/get_all?token="+token).then(response => {
            return response.data;
        });
    },
    async getAllPartner(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/get_all_partner?token="+token).then(response => {
            return response.data;
        });
    },
    async getAllMine(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/get_all_mine?token="+token).then(response => {
            return response.data;
        });
    },
    async getOne(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async updateStato(token, id, stato) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/update_stato?token="+token+"&_id="+id+"&stato="+stato).then(response => {
            return response.data;
        });
    },
    async downloadElenco(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/attivita/download_lista?token="+token, params).then(response => {
            return response.data;
        });
    },
}